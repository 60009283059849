<template>
    <div class="mt-[32px]">
        <div class="card">
            <div>
                <DataTable ref="customerDataTable" :value="data" sortMode="multiple" removableSort
                    v-model:filters="filters"
                    v-model:expandedRows="expandedRows"
                    :globalFilterFields="['registrationDate', 'firstName', 'lastName', 'phoneNumber', 'totalOrders', 'totalSpent' ]"
                    filterDisplay="menu"
                    paginator 
                    :loading="loading"
                    :rows="25" 
                    resizableColumns 
                    columnResizeMode="fit"
                    showGridlines
                    :rowsPerPageOptions="[10, 25, 50, 100]"
                    paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                    currentPageReportTemplate="{first} to {last} of {totalRecords}"
                    stripedRows 
                    dataKey="customerId"
                    tableStyle="min-width: 50rem">
                    <template #header>
                        <span class="text-xl font-bold">Customer Report</span>
                        <span class="flex justify-end">
                            <Button text icon="pi pi-plus" label="Expand All" @click="expandAll" />
                            <Button text icon="pi pi-minus" label="Collapse All" @click="collapseAll" />
                            <IconField>
                                <InputText v-model="filters['global'].value" placeholder="Keyword Search" /> 
                                <InputIcon class="p-iconfield">
                                    <i class="pi pi-search" />
                                </InputIcon>
                            </IconField> &nbsp;&nbsp;&nbsp;
                            <Button icon="pi pi-external-link" label="Export" @click="exportCSV($event)" />
                        </span>
                    </template>
                    <template #empty>No customer data found. </template>
                    <template #loading>Loading Customer data. Please wait. </template>
                    <Column expander style="width:5rem" />
                    <Column field="registrationDate" sortable 
                        header="Registration  Date" 
                        filterField="registrationDate" 
                        dataType="date" 
                        style="min-width: 13rem">
                        <template #body="{ data }">
                            {{ formatDate(data.registrationDate) }}
                        </template>
                        <template #filter="{ filterModel }">
                            <DatePicker v-model="filterModel.value" dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" />
                        </template>
                    </Column>
                    <Column field="firstName" header="First Name" sortable>
                        <template #body="{ data }">
                            {{ data.firstName }}
                        </template>
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" placeholder="Search by first name" />
                        </template>
                    </Column>
                    <Column field="lastName" header="Last Name" sortable>
                        <template #body="{ data }">
                            {{ data.lastName }}
                        </template>
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" placeholder="Search by last name" />
                        </template>
                    </Column>
                    <Column field="phoneNumber" sortable header="Phone Number">
                        <template #body="{ data }">
                            {{ data.phoneNumber }}
                        </template>
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" placeholder="Search by phone" />
                        </template>
                    </Column>
                    <Column field="totalOrders" dataType="numeric" sortable header="Total Orders">
                        <template #body="{ data }">
                            {{ data.totalOrders }}
                        </template>
                        <template #filter="{ filterModel }">
                            <InputNumber v-model="filterModel.value"
                            placeholder="Search by number of orders" />
                        </template>
                    </Column>
                    <Column field="totalSpent" dataType="numeric" sortable header="Total Spent">
                        <template #body="{ data }">
                            {{ formatPrice(data.totalSpent) }}
                        </template>
                        <template #filter="{ filterModel }">
                            <InputNumber v-model="filterModel.value" mode="currency" currency="NGN" locale="en-NG"
                            placeholder="Search by number of orders" />
                        </template>
                    </Column>
                    <template #expansion="slotProps">
                        <div class="p-4">
                            <h1 class="text-l font-bold">Orders for {{ slotProps.data.orderDate }}</h1>
                            <DataTable :value="slotProps.data.orders"
                                stripedRows 
                                 v-model:expandedRows="expandeOrderdRows"
                                dataKey="orderNumber"
                            >
                                <Column expander style="width:5rem" />
                                <Column field="orderDate" header="Order Date" sortable></Column>
                                <Column field="cashier" header="Cashier" sortable></Column>
                                <Column field="paymentMethod" header="Payment Method" sortable></Column>
                                <Column field="orderType" header="Order Type" sortable></Column>
                                <Column field="status" header="Status" sortable></Column>
                                <Column field="totalPrice" header="Total Price" sortable>
                                    <template #body="slotProps">
                                        {{ formatPrice(slotProps.data.totalPrice) }}
                                    </template>
                                </Column>
                                <template #expansion="slotPropsA">
                                    <div class="p-4">
                                        <h1 class="text-l font-bold">Ordered Items {{ slotProps.data.orderDate }}</h1>
                                        <DataTable :value="slotPropsA.data.orderDetails"
                                            stripedRows 
                                            dataKey="menuId"
                                        >
                                            <Column field="menuName" header="Menu" sortable></Column>
                                            <Column field="category" header="Category" sortable></Column>
                                            <Column field="quantity" header="Quantity" sortable></Column>
                                            <Column field="price" header="Price" sortable>
                                                <template #body="{ data }">
                                                    {{ formatPrice(data.price) }}
                                                </template>
                                            </Column>
                                            <Column field="totalPrice" header="Total Price" sortable>
                                                <template #body="{ data }">
                                                    {{ formatPrice(data.totalPrice) }}
                                                </template>
                                            </Column>
                                        </DataTable>
                                    </div>
                                </template>
                            </DataTable>
                        </div>
                    </template>
                </DataTable>
            </div>
        </div>  
    </div>
</template>

<script setup lang="ts">
    import { useStore } from 'vuex';
    import DataTable from 'primevue/datatable';
    import Column from 'primevue/column';
    import InputText from 'primevue/inputtext';    
    import IconField from 'primevue/iconfield'; 
    import InputNumber from 'primevue/inputnumber';   
    import InputIcon from 'primevue/inputicon';
    import DatePicker from 'primevue/datepicker';
    import Button from 'primevue/button';
    import Tag from 'primevue/tag';
    import Select from 'primevue/select';
    import MultiSelect from 'primevue/multiselect';
    import { useCustomerReportHelper } from './Report.helper'
    import { formatPrice, formatDate } from '../../utility/helper/utils.helper'
    const store = useStore();
    const { onToggle, columns, selectedColumns, expandAll, collapseAll, onRowCollapse, onRowExpand, loading, data, expandedRows, expandeOrderdRows, filters, statuses, getSeverity, customerDataTable, exportCSV } =  useCustomerReportHelper(store);

</script>

<style scoped>

</style>