import { jwtDecode } from "jwt-decode";

export const Image = () => {
  console.log('This is an image utility');
} 

export const getPasswordValidationIcon = (value: string) => {
    const password = value;
    if (password.length < 8) {
      return 'mdi:alert-circle'; // Icon for weak password (e.g., Material Design)
    } else if (!/[A-Z]/.test(password)) {
      return 'mdi:caps-lock'; // Icon for missing uppercase letter
    } else if (!/[0-9]/.test(password)) {
      return 'mdi:numeric'; // Icon for missing number
    } else if (!/[^a-zA-Z0-9]/.test(password)) {
      return 'mdi:pound-box'; // Icon for missing symbol
    }
    return 'mdi:check'; // Icon for strong password
}

export const convertToBase64 = (file: File) => {
  return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
  });
};

export const uploadFile = async (file: File) => {
  console.log({file})
  const formData = new FormData();
  formData.append('file', file);

  const response = await fetch('http://localhost:8081/upload', {
      method: 'POST',
      body: formData,
  });

  return response.json();
};

export const formatTimeToAmOrPm = (timeString: string) => {
  if (!timeString) return '';
  const [hours, minutes, seconds] = timeString.split(':').map(Number);
  const formattedHours = hours % 12 || 12;
  const period = hours < 12 ? 'AM' : 'PM';
  
  return `${formattedHours}:${minutes < 10 ? '0' : ''}${minutes} ${period}`;
};


export const addToInputList = (event: any, dataValue: any, formHandler: any) => {
  const { value, name: key } = event.target;
  console.log({dataValue, key})
  
  const newInfoData = dataValue?.[key] == undefined ? dataValue[key] = [] : [...dataValue?.[key]];
  if (!newInfoData.includes(value)) {
      newInfoData.push(value);
      dataValue[key] = newInfoData;
      event.target.value = '';
  }
  formHandler.setValues(dataValue);
}

export const deleteFromInputList = (listType: string, index: string | number, dataValue: any, formHandler: any) => {
  dataValue[listType].splice(index, 1);
  formHandler.setValues(dataValue);
}

export const isTokenExpired = (token: any) => {
  try {
    const decodedToken: any = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    return decodedToken.exp < currentTime;
  } catch (e) {
    return true;
  }
}

export const formatPrice = (value: number) => {
  const formatter = new Intl.NumberFormat('en-NG', {
    style: 'currency',
    currency: 'NGN',
    minimumFractionDigits: 0
  });
  return formatter.format(value);
};

export const formatDate = (value: any) => {
  return value.toLocaleDateString('en-US', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
  });
};