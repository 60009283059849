<template>
    <div>
        <div class="card">
            <div>
                <DataTable ref="salesDataTable" :value="sales" sortMode="multiple" removableSort
                    v-model:filters="filters"
                    v-model:expandedRows="expandedRows"
                    :globalFilterFields="['customer', 'cashier', 'paymentMethod', 'status', 'totalPrice' ]"
                    filterDisplay="menu"
                    paginator 
                    :rows="25" 
                    :loading="loading"
                    resizableColumns 
                    columnResizeMode="fit"
                    showGridlines
                    :rowsPerPageOptions="[10, 25, 50, 100]"
                    paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                    currentPageReportTemplate="{first} to {last} of {totalRecords}"
                    stripedRows 
                    @rowExpand="onRowExpand" 
                    @rowCollapse="onRowCollapse" 
                    dataKey="orderId"
                    tableStyle="min-width: 50rem">
                    <template #header>
                        <span class="text-xl font-bold">Sales Report</span>
                        <div style="text-align:left">
                            <MultiSelect :modelValue="selectedColumns" :options="columns" optionLabel="header" @update:modelValue="onToggle"
                                display="chip" placeholder="Select Columns" />
                        </div>
                        <span class="flex justify-end">
                            <Button text icon="pi pi-plus" label="Expand All" @click="expandAll" />
                            <Button text icon="pi pi-minus" label="Collapse All" @click="collapseAll" />
                            <IconField>
                                <InputText v-model="filters['global'].value" placeholder="Keyword Search" /> 
                                <InputIcon class="p-iconfield">
                                    <i class="pi pi-search" />
                                </InputIcon>
                            </IconField> &nbsp;&nbsp;&nbsp;
                            <Button icon="pi pi-external-link" label="Export" @click="exportCSV($event)" />
                        </span>
                    </template>
                    <template #empty>No sales data found. </template>
                    <template #loading>Loading Sales data. Please wait. </template>
                    <Column expander style="width:5rem" />
                    <Column field="orderDate" sortable header="Order Date"
                        filterField="orderDate" 
                        dataType="date" 
                        style="min-width: 13rem">
                        <template #body="{ data }">
                            {{ formatDate(data.orderDate) }}
                        </template>
                        <template #filter="{ filterModel }">
                            <DatePicker v-model="filterModel.value" dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" />
                        </template>
                    </Column>
                    <Column field="customer" header="Customer" sortable>
                        <template #body="{ data }">
                            {{ data.customer }}
                        </template>
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" placeholder="Search by customer" />
                        </template>
                    </Column>
                    <Column field="cashier" sortable header="Cashier">
                        <template #body="{ data }">
                            {{ data.cashier }}
                        </template>
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" placeholder="Search by cashier" />
                        </template>
                    </Column>
                    <Column field="paymentMethod" sortable header="Payment Method">
                        <template #body="{ data }">
                            {{ data.paymentMethod }}
                        </template>
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" placeholder="Search by payment method" />
                        </template>
                    </Column>
                    <Column field="status" sortable header="Status">
                        <template #body="{ data }">
                            <Tag :value="data.status" :severity="getSeverity(data.status)" />
                        </template>
                        <template #filter="{ filterModel, filterCallback }">
                            <Select v-model="filterModel.value" @change="filterCallback()" :options="statuses" placeholder="Select One" style="min-width: 12rem" :showClear="true">
                                <template #option="slotProps">
                                    <Tag :value="slotProps.option" :severity="getSeverity(slotProps.option)" />
                                </template>
                            </Select>
                        </template>
                    </Column>
                    <Column field="totalPrice" dataType="numeric" sortable header="Amount">
                        <template #body="{ data }">
                            {{ data.totalPrice }}
                        </template>
                        <template #filter="{ filterModel }">
                            <InputNumber v-model="filterModel.value" mode="currency" currency="NGN" locale="en-NG"
                            placeholder="Search by amount" />
                        </template>
                    </Column>
                    <Column v-for="(col, index) of selectedColumns" :field="col.field" :header="col.header" :key="col.field + '_' + index"></Column>
                    <template #expansion="slotProps">
                        <div class="p-4">
                            <h1 class="text-l font-bold">Orders for {{ slotProps.data.orderDate }}</h1>
                            <DataTable :value="slotProps.data.orderDetails">
                                <Column field="category" header="Category" sortable></Column>
                                <Column field="menuName" header="Menu" sortable></Column>
                                <Column field="quantity" header="Quantity" sortable></Column>
                                <Column field="price" header="Price" sortable>
                                    <template #body="slotProps">
                                        {{ formatPrice(slotProps.data.price) }}
                                    </template>
                                </Column>
                            </DataTable>
                        </div>
                    </template>
                </DataTable>
            </div>
        </div>  
    </div>
</template>

<script setup lang="ts">
    import { useStore } from 'vuex';
    import DataTable from 'primevue/datatable';
    import Column from 'primevue/column';
    import InputText from 'primevue/inputtext';  
    import InputNumber from 'primevue/inputnumber';  
    import IconField from 'primevue/iconfield';    
    import InputIcon from 'primevue/inputicon';
    import DatePicker from 'primevue/datepicker';
    import Button from 'primevue/button';
    import Tag from 'primevue/tag';
    import Select from 'primevue/select';
    import MultiSelect from 'primevue/multiselect';
    import { useSalesReportHelper } from './Report.helper'
    import { formatPrice, formatDate } from '../../utility/helper/utils.helper'
    const store = useStore();
    const { onToggle, columns, selectedColumns, expandAll, collapseAll, onRowCollapse, onRowExpand, loading, sales, expandedRows, filters, statuses, getSeverity, salesDataTable, exportCSV } =  useSalesReportHelper(store);
</script>


<style scoped>
</style>